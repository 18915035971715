// body {
//   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//   background-size: 400% 400%;
//   animation: gradient 15s ease infinite;
//   height: 100vh;
// }

.logo {
  height: 50vmax;
  width: 50vmax;
  background-image: url("https://raw.githubusercontent.com/DaniBoie/web-mag/main/src/assets/Soli%20Logo.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  animation: textMove 10s infinite alternate;
}

.text {
  margin-bottom: 20px;
  justify-self: flex-end;
}

// .text:hover {
//   color: rgba(0, 0, 0, 0.3);
// }


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.coming-soon {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
  height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size:xx-large;
  text-underline-offset: 5px;
  color: rgba(255, 255, 255, 0.3);
}

.blob {
  position: absolute;
  width: 50vmin;
  height: 50vmin;
  background: linear-gradient(180deg,
      rgba(47, 184, 255, 0.42) 31.77%,
      #5c9df1 100%);
  mix-blend-mode: color-dodge;
  -webkit-animation: move 25s infinite alternate;
  animation: move 25s infinite alternate;
  transition: 1s cubic-bezier(0.07, 0.8, 0.16, 1);
}

@media screen and (max-width: 500px) {
  
  .blob {
    width: 80vmin;
    height: 80vmin;
  }
}

@media screen and (max-width: 750px) {



  .logo {
    height: 60vmax;
    width: 60vmax;
  }

}

.blob:hover {
  width: 80vmin;
  height: 80vmin;
  -webkit-filter: blur(30px);
  filter: blur(30px);
  box-shadow:
    inset 0 0 0 5px rgba(255, 255, 255, 0.6),
    inset 100px 100px 0 0px #fa709a,
    inset 200px 200px 0 0px #784ba8,
    inset 300px 300px 0 0px #2b86c5;

  
}

.blob:hover .text {
  color: rgba(0, 0, 0, 0.3);
}

@-webkit-keyframes move {
  from {
    transform: rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes move {
  from {
    transform: rotate(-90deg);
    border-radius: 24% 76% 35% 65% / 27% 36% 64% 73%;
  }

  to {
    transform: rotate(-10deg);
    border-radius: 76% 24% 33% 67% / 68% 55% 45% 32%;
  }
}

@keyframes textMove {
  0% {
    transform: translateY(0px) rotate(-1deg);
    
  }

  100% {
    transform: translateY(20px) rotate(0deg);
  }
}